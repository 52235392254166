import React from 'react';
import { Row, Col, Container, TabContent, TabPane, Nav, NavItem, NavLink, Table } from 'reactstrap';
import classnames from 'classnames';
import Suggesties from './Suggesties';
const menu = require("../menu.json");

class MenuList extends React.Component
{
    constructor(props)
    {
        super(props)
        this.state = {
            activeTab: "1"
        }

        this.toggle = this.toggle.bind(this)
    }

    toggle(id)
    {
        this.setState({
            activeTab: id
        })
    }

    render()
    {
        const {activeTab} = this.state;
        console.log(menu)
        return(
            <Container>
                <div className="menu-area" id="menu_area">
                    <Row xs="3"className="menu-list-nav">
                        <Col>
                            <Nav pills>
                                <NavItem className="pill-1">
                                    <NavLink
                                        className={classnames({ active: activeTab === '1' })}
                                        onClick={() => { this.toggle('1'); }}>
                                            Maaltijden
                                    </NavLink>
                                </NavItem>
                                <NavItem className="pill-1">
                                    <NavLink
                                        className={classnames({ active: activeTab === '2' })}
                                        onClick={() => { this.toggle('2'); }}>
                                            Dranken
                                    </NavLink>
                                </NavItem>
                                <NavItem className="pill-1">
                                    <NavLink
                                        className={classnames({ active: activeTab === '3' })}
                                        onClick={() => { this.toggle('3'); }}>
                                            Suggesties
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                        <Col xs="8" className="allergy-msg-menu">
                            <p><b><i>Heb je een allergie? Vergeet dit zeker niet te melden. De samenstelling van de producten kan veranderen. Opgelet: kruisbesmetting is niet 100% uit te sluiten.</i></b></p>
                        </Col>
                    </Row>
                    <Row>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                            <Row>
                                <Col lg="6" md="6">
                                    <div class="menu-list">
                                        <div class="single-menu">
                                            <h3>Voorgerechten</h3>
                                            <Table borderless>
                                                <tbody>
                                                    <tr>
                                                        <td><span>Bruschetta</span></td>
                                                        <td><span>5.00 €</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span>Salade Caprese</span></td>
                                                        <td><span>10.00 €</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span>Antipasto misto</span></td>
                                                        <td><span>18.00 €</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span>Rundscarpaccio</span></td>
                                                        <td><span>18.00 €</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span>Italiaanse ham en meloen</span></td>
                                                        <td><span>18.00 €</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span>Piatto di formaggi</span></td>
                                                        <td><span>20.00 €</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span>Scampi look</span></td>
                                                        <td><span>20.00 €</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span>Scampi Pizzaiola</span></td>
                                                        <td><span>21.00 €</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span>Mozarella met ham en salade</span></td>
                                                        <td><span>19.00 €</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span>Antipasto della casa (min. 2 pers.)</span></td>
                                                        <td><span>35.00 €</span></td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            <h3>Spaghetti</h3>
                                                <Table borderless>
                                                    <tbody>
                                                        <tr>
                                                            <td><span>Aglio olio e peperoncino</span></td>
                                                            <td><span>12.00 €</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span>Ragù</span></td>
                                                            <td><span>15.00 €</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span>Pesto (enkel in de zomer)</span></td>
                                                            <td><span>16.00 €</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span>Amatriciana</span></td>
                                                            <td><span>16.00 €</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span>Carbonara</span></td>
                                                            <td><span>16.00 €</span></td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            <h3>Penne</h3>
                                                <Table borderless>
                                                    <tbody>
                                                        <tr>
                                                            <td><span>Arrabbiata</span></td>
                                                            <td><span>14.00 €</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span>Napoletana</span></td>
                                                            <td><span>14.00 €</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span>Quattro Formaggi</span></td>
                                                            <td><span>20.00 €</span></td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            <h3>Huisbereide Pasta's</h3>
                                                <Table borderless>
                                                    <tbody>
                                                        <tr>
                                                            <td><span>Tagliolini alla Napoletana</span></td>
                                                            <td><span>16.00 €</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span>Tagliatelle ragù</span></td>
                                                            <td><span>17.00 €</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span>Tortellini al pomodoro</span></td>
                                                            <td><span>17.00 €</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span>Ravioli di ricotta al burro e salvia</span></td>
                                                            <td><span>18.00 €</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span>Lasagna al forno (in de oven gebakken)</span></td>
                                                            <td><span>18.00 €</span></td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg="6" md="6">
                                    <div class="menu-list">
                                        <div class="single-menu">
                                            <h3>Pizza's</h3>
                                                <Table borderless>
                                                    <tbody>
                                                        <tr>
                                                            <td><span>Focaccia</span></td>
                                                            <td><span>10.00 €</span></td>
                                                        </tr>
                                                        <tr><td><p>(rozemarijn, olijfolie, ansjovis)</p></td></tr>
                                                        <tr>
                                                            <td><span>Margherita</span></td>
                                                            <td><span>14.00 €</span></td>
                                                        </tr>
                                                        <tr><td><p>(tomaten, mozarella)</p></td></tr>
                                                        <tr>
                                                            <td><span>Calabrese</span></td>
                                                            <td><span>14.00 €</span></td>
                                                        </tr>
                                                        <tr><td><p>(tomaten, mozzarella, look, peperoncino)</p></td></tr>
                                                        <tr>
                                                            <td><span>Vera Napoli</span></td>
                                                            <td><span>14.00 €</span></td>
                                                        </tr>
                                                        <tr><td><p>(tomaten, look, olijfolie, origano)</p></td></tr>
                                                        <tr>
                                                            <td><span>Monte Bianco</span></td>
                                                            <td><span>15.00 €</span></td>
                                                        </tr>
                                                        <tr><td><p>(tomaten, mozzarella, parmigiano)</p></td></tr>
                                                        <tr>
                                                            <td><span>Capri</span></td>
                                                            <td><span>16.00 €</span></td>
                                                        </tr>
                                                        <tr><td><p>(tomaten, mozzarella, champignons, artisjokken)</p></td></tr>
                                                        <tr>
                                                            <td><span>Diavola</span></td>
                                                            <td><span>16.00 €</span></td>
                                                        </tr>
                                                        <tr><td><p>(tomaten, mozzarella, champignons, pikante salami)</p></td></tr>
                                                        <tr>
                                                            <td><span>Quattro Stagioni</span></td>
                                                            <td><span>16.00 €</span></td>
                                                        </tr>
                                                        <tr><td><p>(tomaten, mozzarella, ham, champignons, artisjokken, ansjovis)</p></td></tr>
                                                        <tr>
                                                            <td><span>Tarantina</span></td>
                                                            <td><span>16.00 €</span></td>
                                                        </tr>
                                                        <tr><td><p>(tomaten, mozzarella, ansjovis, olijven, pecorinokaas)</p></td></tr>
                                                        <tr>
                                                            <td><span>Calzone</span></td>
                                                            <td><span>16.00 €</span></td>
                                                        </tr>
                                                        <tr><td><p>(tomaten, kaas, ham, dichtgevouwen deeg)</p></td></tr>
                                                        <tr>
                                                            <td><span>Tonno</span></td>
                                                            <td><span>18.00 €</span></td>
                                                        </tr>
                                                        <tr><td><p>(tomaten, ajuin, tonijn)</p></td></tr>
                                                        <tr>
                                                            <td><span>Chef</span></td>
                                                            <td><span>16.00 €</span></td>
                                                        </tr>
                                                        <tr><td><p>(tomaten, mozzarella, pancettaham, kruiden)</p></td></tr>
                                                        <tr>
                                                            <td><span>Quattro Formaggi</span></td>
                                                            <td><span>18.00 €</span></td>
                                                        </tr>
                                                        <tr><td><p>(tomaten, verschillende kaassoorten)</p></td></tr>
                                                        <tr>
                                                            <td><span>Monte e Valle</span></td>
                                                            <td><span>18.00 €</span></td>
                                                        </tr>
                                                        <tr><td><p>(tomaten, mozzarella, champignons, talleggiokaas, Italiaanse ham)</p></td></tr>
                                                        <tr>
                                                            <td><span>Rucola</span></td>
                                                            <td><span>18.00 €</span></td>
                                                        </tr>
                                                        <tr><td><p>(tomaten, rucolasalade, Italiaanse ham, parmigiano)</p></td></tr>
                                                        <tr>
                                                            <td><span>Scampi</span></td>
                                                            <td><span>18.00 €</span></td>
                                                        </tr>
                                                        <tr><td><p>(tomaten, scampi, ajuin, peterselie, look, kruiden)</p></td></tr>
                                                    </tbody>
                                                </Table>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            </TabPane>
                            <TabPane tabId="2">
                            <Row>
                                <Col lg="6" md="6">
                                    <div class="menu-list">
                                        <div class="single-menu">
                                            <h3>Aperitieven</h3>
                                            <Table borderless>
                                                <tbody>
                                                    <tr>
                                                        <td><span>Martini (rood - wit)</span></td>
                                                        <td><span>6.00 €</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span>Porto (rood - wit)</span></td>
                                                        <td><span>6.00 €</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span>Cinar</span></td>
                                                        <td><span>6.00 €</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span>Gancia</span></td>
                                                        <td><span>6.00 €</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span>Sherry</span></td>
                                                        <td><span>6.00 €</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span>Prosecco</span></td>
                                                        <td><span>7.00 €</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span>Campari</span></td>
                                                        <td><span>7.00 €</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span>Spritz</span></td>
                                                        <td><span>7.50 €</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span>Gin</span></td>
                                                        <td><span>8.00 €</span></td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            <h3>Bieren uit het flesje</h3>
                                                <Table borderless>
                                                    <tbody>
                                                        <tr>
                                                            <td><span>Pils</span></td>
                                                            <td><span>4.00 €</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span>Duvel</span></td>
                                                            <td><span>6.50 €</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span>Peroni (33 cl.)</span></td>
                                                            <td><span>6.00 €</span></td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                                <h3>Abdijbier en Trappisten</h3>
                                                <Table borderless>
                                                    <tbody>
                                                        <tr>
                                                            <td><span>Leffe Blond</span></td>
                                                            <td><span>6.50 €</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span>Orval</span></td>
                                                            <td><span>6.50 €</span></td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            <h3>Frisdranken</h3>
                                                <Table borderless>
                                                    <tbody>
                                                        <tr>
                                                            <td><span>Bruiswater</span></td>
                                                            <td><span>3.00 €</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span>Mineraalwater</span></td>
                                                            <td><span>3.00 €</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span>Coca Cola</span></td>
                                                            <td><span>3.50 €</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span>Coca Cola Light</span></td>
                                                            <td><span>3.50 €</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span>Limonade</span></td>
                                                            <td><span>3.50 €</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span>Tonic Schweppes</span></td>
                                                            <td><span>4.00 €</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span>Lipton Ice Tea</span></td>
                                                            <td><span>4.00 €</span></td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            <h3>Water in flessen</h3>
                                                <Table borderless>
                                                    <tbody>
                                                        <tr>
                                                            <td><span>Bruiswater 0.5 L</span></td>
                                                            <td><span>5.00 €</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span>Bruiswater 1 L</span></td>
                                                            <td><span>10.00 €</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span>Mineraalwater 0.5 L</span></td>
                                                            <td><span>5.00 €</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span>Mineraalwater 1 L</span></td>
                                                            <td><span>10.00 €</span></td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg="6" md="6">
                                    <div class="menu-list">
                                        <div class="single-menu">
                                            <h3>Fruitsappen</h3>
                                                <Table borderless>
                                                    <tbody>
                                                        <tr>
                                                            <td><span>Sinaasappel</span></td>
                                                            <td><span>4.50 €</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span>Gemengde vruchten</span></td>
                                                            <td><span>4.50 €</span></td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            <h3>Warme Dranken</h3>
                                                <Table borderless>
                                                    <tbody>
                                                        <tr>
                                                            <td><span>Espresso</span></td>
                                                            <td><span>3.50 €</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span>Koffie</span></td>
                                                            <td><span>3.50 €</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span>Thee (natuur, kamille, ... )</span></td>
                                                            <td><span>3.50 €</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span>Caffeïnevrije Koffie</span></td>
                                                            <td><span>3.50 €</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span>Cappuccino</span></td>
                                                            <td><span>4.00 €</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span>Latte macchiato</span></td>
                                                            <td><span>4.00 €</span></td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            <h3>Distillaten</h3>
                                                <Table borderless>
                                                    <tbody>
                                                        <tr>
                                                            <td><span>Limoncello</span></td>
                                                            <td><span>7.00 €</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span>Vecchia Romagna (Brandy)</span></td>
                                                            <td><span>8.00 €</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span>Grappa</span></td>
                                                            <td><span>8.00 €</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span>Sambuca</span></td>
                                                            <td><span>8.00 €</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span>Amari (digestief)</span></td>
                                                            <td><span>8.00 €</span></td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            <h3>Huiswijnen</h3>
                                                <Table borderless>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                van de fles
                                                            </th>
                                                            <th>
                                                                glas
                                                            </th>
                                                            <th>
                                                                1/4 L
                                                            </th>
                                                            <th>
                                                                1/2 L
                                                            </th>
                                                            <th>
                                                                1 L
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                   <tbody>
                                                        <tr>
                                                            <td><span>Rode wijn</span></td>
                                                            <td><p>4.50 €</p></td>
                                                            <td><p>9.00 €</p></td>
                                                            <td><p>18.00 €</p></td>
                                                            <td><p>36.00 €</p></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span>Witte wijn</span></td>
                                                            <td><p>4.50 €</p></td>
                                                            <td><p>9.00 €</p></td>
                                                            <td><p>18.00 €</p></td>
                                                            <td><p>36.00 €</p></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span>Rosé wijn</span></td>
                                                            <td><p>4.50 €</p></td>
                                                            <td><p>9.00 €</p></td>
                                                            <td><p>18.00 €</p></td>
                                                            <td><p>36.00 €</p></td>
                                                        </tr>
                                                   </tbody>
                                                </Table>
                                                <Table borderless>
                                                    <tbody>
                                                        <tr>
                                                            <td><span>Prosecco fles</span></td>
                                                            <td><span>36.00 €</span></td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            </TabPane>
                            <TabPane tabId="3">
                            <Row>
                                <Col lg="12" md="12">
                                    <Suggesties />
                                </Col>
                            </Row>
                            </TabPane>
                        </TabContent>
                    </Row>
                </div>
            </Container>
        )
    }
}

export default MenuList;
