/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';

class Gallery extends React.Component {

    render()
    {
        return(
            <div className="gallery-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-5 col-sm-6">
                            <div className="single-gallery mt--480">
                                <figure>
                                    <img
                                        sizes="(max-width: 700px) 100vw, 700px"
                                        srcSet="
                                        img/gallery/g1_vj0ta0_c_scale,w_190.jpg 190w,
                                        img/gallery/g1_vj0ta0_c_scale,w_439.jpg 439w,
                                        img/gallery/g1_vj0ta0_c_scale,w_626.jpg 626w,
                                        img/gallery/g1_vj0ta0_c_scale,w_700.jpg 700w"
                                        src="img/gallery/g1_vj0ta0_c_scale,w_700.jpg"
                                        alt="first-picture" />

                                </figure>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-6">
                            <div className="single-gallery">
                                <figure>
                                    <img
                                        sizes="(max-width: 700px) 100vw, 700px"
                                        srcSet="
                                        img/gallery/g2_p4syfh_c_scale,w_190.jpg 190w,
                                        img/gallery/g2_p4syfh_c_scale,w_421.jpg 421w,
                                        img/gallery/g2_p4syfh_c_scale,w_590.jpg 590w,
                                        img/gallery/g2_p4syfh_c_scale,w_700.jpg 700w"
                                        src="img/gallery/g2_p4syfh_c_scale,w_700.jpg"
                                        alt="second-picture" />
                                </figure>
                            </div>
                        </div>
                        <div className="offset-lg-1 col-lg-5 col-md-5 col-sm-6">
                            <div className="single-gallery mt--280">
                                <figure>
                                    <img
                                        sizes="(max-width: 700px) 100vw, 700px"
                                        srcSet="
                                        img/gallery/g3_yqgfms_c_scale,w_190.jpg 190w,
                                        img/gallery/g3_yqgfms_c_scale,w_535.jpg 535w,
                                        img/gallery/g3_yqgfms_c_scale,w_700.jpg 700w"
                                        src="img/gallery/g3_yqgfms_c_scale,w_700.jpg"
                                        alt="third-picture" />
                                </figure>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="single-gallery">
                                <figure>
                                    <img
                                        sizes="(max-width: 620px) 100vw, 620px"
                                        srcSet="
                                        img/gallery/g4_ykbhjo_c_scale,w_190.jpg 190w,
                                        img/gallery/g4_ykbhjo_c_scale,w_482.jpg 482w,
                                        img/gallery/g4_ykbhjo_c_scale,w_620.jpg 620w"
                                        src="img/gallery/g4_ykbhjo_c_scale,w_620.jpg"
                                        alt="Tagliatelle" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Gallery;