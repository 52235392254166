import React from "react";

class HomeBanner extends React.Component {

    render()
    {
        return (
            <div>
                <div className="home-banner-area relative">
                    <div className="container">
                        <div className="row height align-items-center justify-content-center">
                            <div className="home-banner-content col-lg-12">
                                <h1>Trattoria Dell' Oratoriënhof</h1>
                                <hr></hr>
                                <p><i>La vita è una combinazione di pasta e magia (Federico Fellini)</i></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="go-down">
                    <a href="#menu_area">
                        <img className="go-down-img" src="img/go-down.png" alt="go-down icon" />
                    </a>
                </div>
            </div>
        )
    }
}

export default HomeBanner;