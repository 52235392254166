import React from 'react';

import {Container} from 'reactstrap';

class MenuBanner extends React.Component
{
    render()
    {
        return(
            <div className="banner-area relative">
                <Container>
                    <div class="row height align-items-center justify-content-center">
                        <div class="banner-content col-lg-12">
                            <h1>Trattoria Dell' Oratoriënhof</h1>
                            <hr />
                            <div class="breadcrmb">
                                <p>
                                    <a href="/">home</a>
                                    <span> > > </span>
                                    <a href="/menu">menu</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        )
    }
}

export default MenuBanner;