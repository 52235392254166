import React from 'react';
import HomeBanner from '../components/HomeBanner';
import Suggesties from '../components/Suggesties';
import Gallery from '../components/Gallery';
import Contact from '../components/Contacts';

class Home extends React.Component {

    render()
    {
        return(
            <div>
                <HomeBanner />
                <Suggesties />
                <Gallery />
                <Contact />
            </div>
        )
    }
}

export default Home;