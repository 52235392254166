import React from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    NavbarText,
    Col,
    Row
  } from 'reactstrap';
import { FiMenu, FiPhoneCall } from 'react-icons/fi';

import Scrollspy from 'react-scrollspy'

class NavHeader extends React.Component
{

    constructor(props)
    {
        super(props)
        this.state = {
            isOpen: false
        }

        this.toggle = this.toggle.bind(this)
    }

    toggle()
    {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }
    render()
    {
        const {isOpen} = this.state;

        return(
            <div className="header-area">
                <div className="container">
                    <div className="header-wrap">
                        <div className="header-top d-flex justify-content-center align-items-center">
                            <Scrollspy items={ ['contact', 'suggesties'] } currentClassName="is-current">
                                <Navbar expand="md">
                                    <NavbarToggler onClick={this.toggle}>
                                        <Row>
                                            <Col xs={3} style={{textAlign: "left"}}>
                                                <img className="mx-auto" style={{width: '100%'}}src="img/logo-2.png" alt="logo" />
                                            </Col>
                                            <Col xs={9}style={{textAlign: "right"}}>
                                                <FiMenu></FiMenu>
                                            </Col>
                                        </Row>
                                    </NavbarToggler>
                                    <Collapse isOpen={isOpen} navbar>
                                        <div className="col menu-left">
                                            <Nav className="col navbar navbar-expand-md justify-content-end" navbar>
                                                <NavItem>
                                                    <NavLink href="/">Home</NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink href="/menu">Menu</NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink href="/#suggesties">Suggesties</NavLink>
                                                </NavItem>
                                            </Nav>
                                        </div>
                                        <NavbarBrand className="logo" href="/"><img className="mx-auto hide-sm" src="img/logo-2.png" alt="logo" /></NavbarBrand>
                                        <div className="menu-right">
                                            <Nav className="col navbar navbar-expand-md justify-content-center w-100" navbar>
                                                <NavItem className="hide-lg">
                                                    <NavLink href="/">Home</NavLink>
                                                </NavItem>
                                                <NavItem className="hide-lg">
                                                    <NavLink href="/menu">Menu</NavLink>
                                                </NavItem>
                                                <NavItem className="hide-lg">
                                                    <NavLink href="/#suggesties">Suggesties</NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink href="/#contact">Info</NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink href="/#contact">Reserveer</NavLink>
                                                </NavItem>
                                                <NavbarText><FiPhoneCall/> +32 16 22 81 05</NavbarText>
                                            </Nav>
                                        </div>
                                    </Collapse>
                                </Navbar>
                            </Scrollspy>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default NavHeader;