/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';

import Footer from '../components/Footer';
import NavHeader from '../components/NavHeader';
import Home from './Home';
import Menu from './Menu';

class Main extends React.Component {

    render()
    {
        return (
            <Router>
               <NavHeader />
                    <Switch>
                        <Route exact path={"/"}> <Home /> </Route>
                        <Route path={"/menu"}> <Menu /> </Route>
                        <Redirect from={'/menu.html'} to={'menu'} key={'legacy'} />
                        <Redirect from={'/'} to={'/'} key={'default'} />
                    </Switch>
                <Footer />
           </Router>
        )
    }
}

export default Main