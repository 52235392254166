import React from 'react';
import { Table } from 'reactstrap';
import { suggestionService } from '../api/suggestions.api';

class Suggesties extends React.Component {

    constructor(props)
    {
        super(props)
        this.state = {
            suggestions: null,
            loading: true,
            error: false
        }
    }

    componentDidMount(props)
    {
        suggestionService.getSuggestions()
            .then(result => {
                this.setState({
                    suggestions: result.data,
                    loading: false,
                    error: false
                })
            })
            .catch(error => {
                this.setState({
                    suggestions: null,
                    loading: false,
                    error: true
                })
            })
    }

    render()
    {
        return(
            <div className="menu-area" id="menu_area" data-spy="scroll">
                <div id="suggesties" className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-5">
                            <div className="section-title relative">
                                <h1>
                                    Suggesties <br />
                                </h1>
                                <p>Kan je moeilijk kiezen? Wij maken het gemakkelijk voor jou. Geniet van de Italiaanse keuken door een gerecht te kiezen uit onze lijst met suggesties.</p>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-7">
                            <div className="menu-list">
                                <div className="single-menu">
                                    <h3>Suggesties</h3>
                                    {this.state.loading &&
                                        <p>Bezig met het laden van de suggesties ...</p>
                                    }
                                    {!this.state.loading && this.state.suggestions &&
                                        <Table borderless>
                                            <tbody>
                                                {this.state.suggestions.map(suggestie => {
                                                    return(
                                                        <tr key={suggestie.naam}>
                                                            <td><span>{suggestie.naam}</span></td>
                                                            <td><span>{`${suggestie.prijs} €`}</span></td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    }
                                    { !this.state.loading && this.state.error &&
                                        <p>Vraag achter de suggesties in ons restaurant.</p>
                                    }
                                </div>
                                <hr />
                                <a href="/menu" style={{textDecoration: 'none'}} className="primary-btn text-uppercase">Volledig menu</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Suggesties;