import React from 'react';
import MenuBanner from '../components/MenuBanner';
import MenuList from '../components/MenuList';

class Menu extends React.Component
{
    render()
    {
        return(
            <div>
                <MenuBanner />
                <MenuList />
            </div>
        )
    }
}

export default Menu;